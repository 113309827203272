
/*import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
//import Vuetify from 'vuetify'; // Importar Vuetify
import Vue3EasyDataTable from 'vue3-easy-data-table';
import '@fortawesome/fontawesome-free/css/all.min.css';
const app = createApp(App);
app.component('EasyDataTable', Vue3EasyDataTable);
app.use(store);
app.use(router);

app.mount('#app');*/
// main.js
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { componentsMap } from './router/componentsMap'; // Importa el mapa de componentes
import Vue3EasyDataTable from 'vue3-easy-data-table';
import '@fortawesome/fontawesome-free/css/all.min.css';
import "vue3-easy-data-table/dist/style.css";

import 'vue-multiselect/dist/vue-multiselect.css';

const app = createApp(App);

app.component('EasyDataTable', Vue3EasyDataTable);

store.dispatch('loadRoutes').then(() => {
 // const dynamicRoutes = store.getters.getDynamicRoutes;
 // console.log(dynamicRoutes);

  var items = store.getters.getDynamicRoutes.map(item => ({
    component:item.component,
    meta: item.meta,
    name: item.name,
    path: item.path
   
}));
    const dynamicRoutes = items;
  dynamicRoutes.forEach((route) => {
//console.log(JSON.stringify(route) + 'aqui');
    if (componentsMap[route.component]) {
      route.component = componentsMap[route.component];
      router.addRoute(route);
    } else {
      console.warn(`Componente ${route.component} no encontrado en el mapa de componentes.`);
    }
  });

  app.use(store);
  app.use(router);
  //app.mount('#app');

  store.dispatch('initializeStore').then(() => {
    app.mount('#app');
  });
});


