/*import { createStore } from 'vuex'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})*/
/*import { createStore } from 'vuex'

export default createStore({
  state: {
    auth: {
      isAuthenticated: false, // Estado inicial de autenticación,
      roles: [], // Almacena los roles del usuario
      nuevosRoles:[],
      permisos:[]
    },
    
  },
  getters: {
  },
  mutations: {
    setIsAuthenticated(state, isAuthenticated) {
      state.auth.isAuthenticated = isAuthenticated;
    },
    
    setUserRoles(state, roles) {
     // alert(roles+'index');
      state.auth.roles = [roles]; // Corregir el nombre del estado a state.user.roles
    },
    actualizarRoles(state, nuevosRoles) {
      //alert('llege');
      state.auth.roles = [nuevosRoles];
    },
    setPermisos(state, permisos){
      state.auth.permisos = permisos
    }
  },
  actions: {
  },
  modules: {
  }
})*/
import { createStore } from 'vuex';
import api from '../service/Api'; // Asegúrate de tener la función para obtener rutas

const store = createStore({
  state: {
    auth: {
      isAuthenticated: false,
      roles: [],
      permisos: [],
    },
    dynamicRoutes: [],
  },
  getters: {
    getDynamicRoutes: (state) => state.dynamicRoutes,
  },
  mutations: {
    setIsAuthenticated(state, isAuthenticated) {
      state.auth.isAuthenticated = isAuthenticated;
    },
    setUserRoles(state, roles) {
      state.auth.roles = [roles];
      localStorage.setItem('userRoles', [roles]); //para que al actualizar no se pierda la informacion
    },
    setPermisos(state, permisos) {
      state.auth.permisos = permisos;
      localStorage.setItem('userPermisos', JSON.stringify(permisos));
    },
    setDynamicRoutes(state, routes) {
      state.dynamicRoutes = routes;
    },
  },
  actions: {
    async loadRoutes({ commit }) {
      try {
        const response = await api.getRoutes();
        commit('setDynamicRoutes', response.data);
      } catch (error) {
        console.error('Error al obtener las rutas desde la API:', error);
      }
    },
    initializeStore({ commit }) {
      const userRoles = localStorage.getItem('userRoles');
      const userPermisos =localStorage.getItem('userPermisos');
      if (userRoles) {
        commit('setUserRoles', userRoles);
      }
      if (userPermisos) {
        commit('setPermisos', JSON.parse(userPermisos));
      }
    }
  },
  modules: {},
});

export default store;

