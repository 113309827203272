// src/router/componentsMap.js
export const componentsMap = {
    'RolesView': () => import('../views/RolesView.vue'),
    'UsuariosView': () => import('../views/UsuariosView.vue'),
    'AltaUsuarioView': () => import('../views/AltaUsuarioView.vue'),
    'ClientesView': () => import('../views/ClientesView.vue'),
    'AltaClientesView': () => import('../views/AltaClientesView.vue'),
    'CategoriasView': () => import('../views/CategoriasView.vue'),
    'EditarPerfilView': () => import('../views/EditarPerfilView.vue'),
    'ProductosView': () => import('../views/ProductosView.vue'),
    'PermisosView': () => import('../views/PermisosView.vue'),
    'EditarUsuarioView': () => import('../views/EditarUsuarioView.vue'),
    'SubcategoriasView': () => import('../views/SubcategoriasView.vue'),
    'MarcasView': () => import('../views/MarcasView.vue'),
    'ModelosView': () => import('../views/ModelosView.vue'),
    'EditarClienteView': () => import('../views/EditarClienteView.vue'),
    'PaginaView': () => import('../views/PaginaView.vue'),
    'LayoutView': () => import('../views/EstructuraView.vue'),
    'EditarProductoView': () => import('../views/EditarProductoView.vue'),
    'ParametrosSapView': () => import('../views/ParametrosSapView.vue'),
    'ParametrosSapView': () => import('../views/ParametrosSapView.vue'),
    'SucursalesView': () => import('../views/SucursalesView.vue'),
    'AltaSucursalView': () => import('../views/AltaSucursalView.vue'),
    'EditarSucursalView': () => import('../views/EditarSucursalView.vue'),
    'TerminosCondicionesView': () => import('../views/TerminosCondicionesView.vue'),
    'AvisoPrivacidadView': () => import('../views/AvisoPrivacidadView.vue'),
    'PreguntasView': () => import('../views/PreguntasView.vue'),
    'RedesSocialesView': () => import('../views/RedesSocialesView.vue'),
    'ListaPreciosView': () => import('../views/ListaPreciosView.vue'),
    'ManualesView': () => import('../views/ManualesView.vue'),
    'AltaManualesView': () => import('../views/AltaManualesView.vue'),
    'EditarManualView': () => import('../views/EditarManualView.vue'),
    'BlogView': () => import('../views/BlogView.vue'),
    'AltaBlogView': () => import('../views/AltaBlogView.vue'),
    'EditarBlogView': () => import('../views/EditarBlogView.vue'),
    'CatalogosView': () => import('../views/CatalogosView.vue'),
    'AltaCatalogoView': () => import('../views/AltaCatalogoView.vue'),
    'EditarCatalogoView': () => import('../views/EditarCatalogoView.vue'),
    'BannerView': () => import('../views/BannerView.vue'),
    'AltaBannerView': () => import('../views/AltaBannerView.vue'),
    'EditarBannerView': () => import('../views/EditarBannerView.vue'),
    'NewsletterView': () => import('../views/NewsletterView.vue'),
    'DescargaCatalogoView': () => import('../views/DescargaCatalogoView.vue'),
    'PedidosView': () => import('../views/PedidosView.vue'),
    'FormularioContactoView': () => import('../views/FormularioContactoView.vue')

    

    
    
  };
  