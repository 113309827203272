<template>
  <!--<nav>-->
    <!--<router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>-->
  <!--</nav>-->
   <!--<nav class="navbar navbar-expand-lg navbar-dark bg-success" style="background-color: #e3f2fd;">-->
  <!--<nav class="navbar navbar-expand-lg  " style="background-color: #ffffff;">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">GESTION ACADEMICA</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Inicio</a>
        </li>
        
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Estudiantes
          </a>
          <ul class="dropdown-menu">
          <li> <router-link class="dropdown-item" to="/">Listar Estudiantes</router-link></li>
            <li><router-link class="dropdown-item" to="/createE">Registrar Nuevo</router-link></li>
            
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>-->
  <router-view/>
</template>

