// src/services/api.js
import axios from 'axios';
import { API_URL } from '@/environment'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export default {
  getRoutes() {
     /*var datos = [
        {
          "path": "/inicio",
          "name": "Inicio",
          "component": "LayoutView",
          "meta": {
            "requiresAuth": true,
            "allowedRoles": ["Administrador", "Marketing","Vendedor"]
          }
        },
        {
          "path": "/roles",
          "name": "Roles",
          "component": "RolesView",
          "meta": {
            "requiresAuth": true,
            "allowedRoles": ["Administrador"]
          }
        },
        {
          "path": "/usuarios",
          "name": "users",
          "component": "UsuariosView",
          "meta": {
            "requiresAuth": true,
            "allowedRoles": ["Administrador",,"Vendedor"]
          }
        },
        {
            "path": "/marcas",
            "name": "marcas",
            "component": "MarcasView",
            "meta": {
              "requiresAuth": true,
              "allowedRoles": ["Administrador","Vendedor"]
            }
          },
          {
            "path": "/clientes",
            "name": "Clientes",
            "component": "ClientesView",
            "meta": {
              "requiresAuth": true,
              "allowedRoles": ["Administrador"]
            }
          },
          {
            "path": "/categorias",
            "name": "categorias",
            "component": "CategoriasView",
            "meta": {
              "requiresAuth": true,
              "allowedRoles": ["Administrador"]
            }
          },
          {
            "path": "/editarusuario",
            "name": "editarusuario",
            "component": "EditarUsuarioView",
            "meta": {
              "requiresAuth": true,
              "allowedRoles": ["Administrador"]
            }
          }
          
          
      ];*/
      //return datos;
    return apiClient.post(`${API_URL}/api/getAllRolesWithPermissions`); // Ajusta la URL según sea necesario
   // return apiClient.post('https://api.pruebas.pulidotp.com/api/getAllRolesWithPermissions'); // Ajusta la URL según sea necesario
    
  }
  };



  
