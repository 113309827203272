/*import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store'; // Importa el store de Vuex
import HomeView from '../views/HomeView.vue'
import api from '../service/Api'; // Importa la función para obtener rutas
import EditarClienteView from '../views/EditarClienteView.vue'
import AltaUsuarioView from '../views/AltaUsuarioView.vue'
import AltaClientesView from '../views/AltaClientesView.vue'
import EditarPerfilView from '../views/EditarPerfilView.vue'
import EditarUsuarioView from '../views/EditarUsuarioView.vue'

const componentsMap = {
    'RolesView': () => import('../views/RolesView.vue'),
    'UsuariosView': () => import('../views/UsuariosView.vue'),
    'AltaUsuarioView': () => import('../views/AltaUsuarioView.vue'),
    'ClientesView': () => import('../views/ClientesView.vue'),
    'AltaClientesView': () => import('../views/AltaClientesView.vue'),
    'CategoriasView': () => import('../views/CategoriasView.vue'),
    'EditarPerfilView': () => import('../views/EditarPerfilView.vue'),
    'ProductosView': () => import('../views/ProductosView.vue'),
    'PermisosView': () => import('../views/PermisosView.vue'),
    'EditarUsuarioView': () => import('../views/EditarUsuarioView.vue'),
    'SubcategoriasView': () => import('../views/SubcategoriasView.vue'),
    'MarcasView': () => import('../views/MarcasView.vue'),
    'ModelosView': () => import('../views/ModelosView.vue'),
    'EditarClienteView': () => import('../views/EditarClienteView.vue'),
    'PaginaView': () => import('../views/PaginaView.vue'),
    'LayoutView': () => import('../views/EstructuraView.vue'),
  };
let routes = [
  {
    path: '/',
    name: 'Login',
    //component: PaginaView
    component: componentsMap['PaginaView'], // Asegúrate de que esta clave coincida
  },
  {
    path: '/inicio',
    name: 'Inicio',
    //component: LayoutView,
    component: componentsMap['LayoutView'], // Asegúrate de que esta clave coincida
    meta: { requiresAuth: true ,allowedRoles: ['Administrador','Marketing','Vendedor']}
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: EditarPerfilView,
    meta: { requiresAuth: true }
  },
  {
    path: '/editarusuario',
    name: 'editarusuario',
    //component: EditarUsuarioView,
    component: componentsMap['EditarUsuarioView'], // Asegúrate de que esta clave coincida

    meta: { requiresAuth: true}
  },
  {
    path: '/altacustomers',
    name: 'Altalientes',
    component: componentsMap['AltaClientesView'],
    meta: { requiresAuth: true }
  },
  {
    path: '/editarcliente',
    name: 'editarCliente',
    component: EditarClienteView,
    meta: { requiresAuth: true}
  },
  // Puedes añadir rutas estáticas aquí si es necesario
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const userRoles = store.state.auth.roles; // Accede al estado de los roles del usuario

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.meta.requiresAuth && isAuthenticated) {
    try {
      const response = await api.getRoutes();
console.log(response.data +'respuesta');
    
      const apiRoutes = response.data;

      apiRoutes.forEach((route) => {
        //console.log(apiRoutes);
        //route.component = loadView(route.component); // Carga perezosa del componente
        //router.addRoute(route); // Añade la ruta al enrutador
        if (componentsMap[route.component]) {
            route.component = componentsMap[route.component];
            router.addRoute(route);
          } else {
            console.warn(`Componente ${route.component} no encontrado en el mapa de componentes.`);
          }
      });

      if (!userRoles || userRoles.length === 0) {
        next({ name: 'Login' });
      } else {
        const allowedRoles = to.meta.allowedRoles || [];
        const hasPermission = userRoles.some((role) => allowedRoles.includes(role));
        if (hasPermission) {
          next();
        } else {
          alert('No tienes permiso para acceder a esta página');
          next({ name: 'Login' });
        }
      }
    } catch (error) {
      console.error('Error al obtener las rutas desde la API:', error);
      next({ name: 'Login' });
    }
  } else {
    next();
  }
});

  
export default router;*/
// router/index.js
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '../store';
import api from '../service/Api';
import { componentsMap } from './componentsMap'; // Importa el mapa de componentes


const staticRoutes = [
  {
    path: '/',
    name: 'Login',
    component: componentsMap['PaginaView'],
  },
 /* {
    path: '/inicio',
    name: 'Inicio',
    component: componentsMap['LayoutView'],
    meta: { requiresAuth: true, allowedRoles: ['Administrador', 'Marketing', 'Vendedor'] },
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: componentsMap['EditarPerfilView'],
    meta: { requiresAuth: true },
  },
  {
    path: '/editarusuario',
    name: 'editarusuario',
    component: componentsMap['EditarUsuarioView'],
    meta: { requiresAuth: true },
  },
  {
    path: '/altacustomers',
    name: 'Altalientes',
    component: componentsMap['AltaClientesView'],
    meta: { requiresAuth: true },
  },
  {
    path: '/editarcliente',
    name: 'editarCliente',
    component: componentsMap['EditarClienteView'],
    meta: { requiresAuth: true },
  },*/
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: staticRoutes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  const userRoles = store.state.auth.roles;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'Login' });
  } else if (to.meta.requiresAuth && isAuthenticated) {
    //alert(store.state.dynamicRoutes.length);
    if (!store.state.dynamicRoutes.length) {
        //alert('aqui');
      await store.dispatch('loadRoutes');
      var items = store.getters.getDynamicRoutes.map(item => ({
        component:item.component,
        meta: item.meta,
        name: item.name,
        path: item.path
       
    }));
        const dynamicRoutes = items;
     // const dynamicRoutes = store.getters.getDynamicRoutes;

      dynamicRoutes.forEach((route) => {
        if (componentsMap[route.component]) {
            console.log(componentsMap);
          route.component = componentsMap[route.component];
          router.addRoute(route);
        } else {
          console.warn(`Componente ${route.component} no encontrado en el mapa de componentes.`);
        }
      });

      next({ ...to, replace: true });
    } else {
      const allowedRoles = to.meta.allowedRoles || [];
    //alert(userRoles);
        //alert(allowedRoles);
      const hasPermission = userRoles.some((role) => allowedRoles.includes(role));
      
//alert(hasPermission);
      if (hasPermission) {
        //alert(hasPermission);
        next();
      } else {
        //alert('No tienes permiso para acceder a esta página');
        next({ name: 'Login' });
      }
    }
  } else {
    next();
  }
});

export default router;

